/********** RESETS **********/

html,
body {
	position: relative;
	width: 100%;
	height: auto;
}

html,
body,
*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
}

body {
	min-width: 320px;
	font-family: $fontStack-primary;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: "liga" on;
	color: $color-steel;
	background: #FFFFFF;
}

article,
section,
main,
nav,
header,
footer,
figure,
figcaption,
img {
	display: block;
}

a,
a img {
	border: none;
}