@import "_sass/common";
@import "_sass/base";



/******* COMMON *******/

body,
p {
	font-size: $fontSize-normal;
	line-height: $fontSize-normal-lineHeight;
}

h1 {
	@extend %text-extraLarge;
}

h2 {
	@extend %text-large;
}

a {
	color: $color-blue;
	text-decoration: underline; 
}

a:hover {
	color: $color-blueDark;
}



/******* GLOBAL PADDING *******/

.globalPadding {
	margin: 0 auto 0 auto;
	padding-left: 40px;
	padding-right: 40px;
	max-width: $maxWidth;
}

.globalPadding:after {
	@include clearFix;
}



/******* GLOBAL SYSTEM MESSAGE *******/

$messageHeight: 40px;
$messageIconSizeLarge: 10px;
$messageIconSizeSmall: 8px;
$messageButtonSizeLarge: 100px;
$messageTextPaddingLarge: 15px;
$messageButtonSizeSmall: 70px;
$messageTextPaddingSmall: 10px;

#globalSystemMessage {
	z-index: 100;
	width: 100%;
	height: $messageHeight;
	color: #FFFFFF;
}

#globalSystemMessage.expanded {
	height: auto;
}

#globalSystemMessage > .container {
	z-index: 10;
	position: relative;
	background: $color-blueDark;
}

#globalSystemMessage.marketing > .container {
	background: #23CF5F;
}

#globalSystemMessage > .container > .wrapper {
	padding-top: 0;
	padding-bottom: 0;
	display: block;
	width: 100%;
	height: 100%;
}

#globalSystemMessage > .container > .wrapper:after {
	@include clearFix;
}

#globalSystemMessage .icon,
#globalSystemMessage .text {
	float: left;
	min-height: $messageHeight;
}

#globalSystemMessage .icon {
	position: relative;
	width: $messageIconSizeLarge;
}

.globalSystemMessage .icon:before,
.globalSystemMessage .icon:after {
	content: "";
	position: absolute;
	display: block;
	left: 50%;
	top: 50%;
	border-radius: 50%;
	background: #FFFFFF;
}

.globalSystemMessage .icon:before {
	margin-left: -($messageIconSizeLarge / 2);
	margin-top: -($messageIconSizeLarge / 2);
	width: $messageIconSizeLarge;
	height: $messageIconSizeLarge;
}

$systemMessageIconOpacity: 0.5;

.globalSystemMessage .icon:after {
	opacity: $systemMessageIconOpacity;
	margin-left: -($messageIconSizeLarge * 3 / 2);
	margin-top: -($messageIconSizeLarge * 3 / 2);
	width: $messageIconSizeLarge * 3;
	height: $messageIconSizeLarge * 3;
	@include animation (1.5s pulse infinite linear);
}

@-webkit-keyframes pulse {
	0% { -webkit-transform: scale(0); }
	50% { opacity: $systemMessageIconOpacity; }
	100% { opacity: 0; -webkit-transform: scale(1); }
}

@-moz-keyframes pulse {
	0% { -moz-transform: scale(0); }
	50% { opacity: $systemMessageIconOpacity; }
	100% { opacity: 0; -moz-transform: scale(1); }
}

@-ms-keyframes pulse {
	0% { -ms-transform: scale(0); }
	50% { opacity: $systemMessageIconOpacity; }
	100% { opacity: 0; -ms-transform: scale(1); }
}

@-o-keyframes pulse {
	0% { -o-transform: scale(0); }
	50% { opacity: $systemMessageIconOpacity; }
	100% { opacity: 0; -o-transform: scale(1); }
}

@keyframes pulse {
	0% { transform: scale(0); }
	50% { opacity: $systemMessageIconOpacity; }
	100% { opacity: 0; transform: scale(1); }
}

#globalSystemMessage .text,
#globalSystemMessage .expand {
	@extend %text-extraSmall;
}

#globalSystemMessage .text {
	position: relative;
	padding: 13px 0 0 $messageTextPaddingLarge;
	@include calc (width, "100% - #{$messageIconSizeLarge + $messageTextPaddingLarge}");
	height: auto;
	line-height: $fontSize-extraSmall-lineHeight;
	text-align: justify;
	overflow: hidden;
	white-space: nowrap;
}

#globalSystemMessage.overflow .text {
	@include calc (width, "100% - #{$messageIconSizeLarge + $messageButtonSizeLarge + $messageTextPaddingLarge}");
}

#globalSystemMessage.expanded .text {
	padding-bottom: 13px;
	height: auto;
	overflow: visible;
	white-space: normal;
	word-break: break-word;
}

#globalSystemMessage .text:after {
	content: none;
	z-index: 10;
	position: absolute;
	right: 0;
	top: 0;
	display: none;
	width: $messageButtonSizeLarge;
	height: 100%;
	background: -moz-linear-gradient(left, rgba($color-blueDark, 0) 0%, rgba($color-blueDark, 1) 100%);
	background: -webkit-linear-gradient(left, rgba($color-blueDark, 0) 0%, rgba($color-blueDark, 1) 100%);
	background: linear-gradient(to right, rgba($color-blueDark, 0) 0%, rgba($color-blueDark, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#254290', endColorstr='#254290', GradientType=1);
}

#globalSystemMessage.overflow .text:after {
	content: "";
	display: block;
}

#globalSystemMessage.marketing .text:after {
	background: -moz-linear-gradient(left, rgba(35, 207, 95, 0) 0%, rgba(35, 207, 95, 1) 100%);
	background: -webkit-linear-gradient(left, rgba(35, 207, 95, 0) 0%, rgba(35, 207, 95, 1) 100%);
	background: linear-gradient(to right, rgba(35, 207, 95, 0) 0%, rgba(35, 207, 95, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23CF5F', endColorstr='#23CF5F', GradientType=1);
}

#globalSystemMessage.expanded .text:after {
	content: "";
	display: none;
}

#globalSystemMessage .text > b {
	font-weight: 700;
}

#globalSystemMessage .text a {
	color: inherit;
}

#globalSystemMessage .expand {
	margin-left: $messageTextPaddingLarge;
	margin-top: 10px;
	display: none;
	width: $messageButtonSizeLarge;
	height: 20px;
	color: #FFFFFF;
	text-decoration: none;
	border-radius: 20px;
	background: lighten($color-blueDark, 15%);
}

#globalSystemMessage.overflow .expand {
	display: inline-table;
}

#globalSystemMessage.marketing .expand {
	background: lighten(#23CF5F, 10%);
}

#globalSystemMessage .expand:hover {
	text-decoration: underline;
	background: lighten($color-blueDark, 10%);
}

#globalSystemMessage.marketing .expand:hover {
	background: darken(#23CF5F, 10%);
}

#globalSystemMessage .expand > .wrapper {
	position: relative;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	text-align: center;
}

#globalSystemMessage .expand .hide,
#globalSystemMessage.expanded .expand .show {
	display: none;
}

#globalSystemMessage.expanded .expand .hide,
#globalSystemMessage .expand .show {
	display: table-cell;
}



/******* GLOBAL HEADER *******/

$menuHeight: 90px;
$menuLogoWidth: 110px;
$menuLogoHeight: 50px;

#globalHeader,
#globalHeader .nav .nav-logo,
#globalHeader .nav .nav-navbar > li {
	@include transition (all 0.5s $easeOutExpo);
}

#globalHeader {
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $color-blue;
}

#globalHeader.large {
	background: transparent;
}

#globalHeader .hamburger {
	z-index: 10;
	position: relative;
	float: right;
	display: none;
	width: 70px;
	height: 50px;
}

#globalHeader .hamburger:before {
	content: "";
	position: absolute;
	left: 20px;
	top: 50%;
	display: block;
	margin-top: -10px;
	width: 30px;
	height: 20px;
	background: url("../../img/global/globalNav_icons.svg") -10px 0 no-repeat transparent;
}

body.menu #globalHeader .hamburger:before {
	left: 25px;
	width: 20px;
	background-position: -40px 0;
}

#globalHeader .nav {
	z-index: 0;
	position: relative;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	width: 100%;
	height: 100%;
}

#globalHeader .nav:after {
	@include clearFix;
}

#globalHeader .nav .nav-logo {
	z-index: 10;
	position: absolute;
	left: 40px;
	top: ($menuHeight / 2) - ($menuLogoHeight / 2) + 5px;
	display: block;
	width: $menuLogoWidth;
	height: $menuLogoHeight;
	background: url("../../img/global/TNGLOGOWhite.png") left center no-repeat transparent;
	background-size: contain;
}

#globalHeader .nav ul {
	list-style: none;
}

#globalHeader .nav .nav-navbar,
#globalHeader .nav .nav-navbar > li > a {
	display: block;
}

#globalHeader .nav .nav-navbar {
	padding-left: $menuLogoWidth + 20px;
}

#globalHeader .nav .nav-navbar:after {
	@include clearFix;
}

#globalHeader .nav .nav-navbar > li {
	position: relative;
	display: block;
	float: left;
	height: $menuHeight;
}

#globalHeader .nav .nav-navbar > li.navbar-home {
	display: none;
}

#globalHeader .nav .nav-navbar > li.navbar-language {
	float: right;
}

#globalHeader .nav .nav-navbar > li a {
	padding: 0 14px 0 14px;
	font-family: $fontStack-secondary;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	@extend %text-small;
}

#globalHeader .nav .nav-navbar > li > a {
	opacity: .8;
	color: #FFFFFF;
	text-transform: uppercase;
	border-top: 5px solid transparent;
	padding-bottom: 5px;
	display: table;
	width: 100%;
	height: 100%;
	@include transition (opacity 0.25s $easeOutExpo);
}

#globalHeader .nav .nav-navbar > li > a > .wrapper {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	height: 100%;
}

#globalHeader .nav .nav-navbar > li.navbar-dropdown > a > .wrapper:after {
	content: "";
	display: inline-block;
	margin-left: 8px;
	margin-bottom: 2px;
	width: 10px;
	height: 6px;
	background: url("../../img/global/globalNav_icons.svg") 0 0 no-repeat transparent;
}

#globalHeader .nav .nav-navbar > li.current > a {
	font-weight: 800;
	border-top-color: #FFFFFF;
}

#globalHeader .nav .nav-navbar > li.current > a,
#globalHeader .nav .nav-navbar > li > a:hover,
#globalHeader .nav .nav-navbar .navbar-submenu:hover + a {
	opacity: 1;
}

#globalHeader .nav .nav-navbar .navbar-submenu {
	pointer-events: none;
	opacity: 0;
	position: absolute;
	left: 50%;
	top: $menuHeight - ($menuHeight / 4);
	margin-left: -(170px / 2) - 10px;
	width: 170px;
	border-radius: 5px;
	background: lighten($color-blue, 33%);
	@include transition (all 0.4s $easeOutExpo);
	@include transform (translateY(-5px));
}

#globalHeader .nav .nav-navbar .navbar-dropdown:hover .navbar-submenu,
#globalHeader .nav .nav-navbar .navbar-dropdown .navbar-submenu:hover {
	pointer-events: auto;
	opacity: 1;
	@include transform (translateY(0px));
}

#globalHeader .nav .nav-navbar .navbar-submenu:before {
	content: "";
	position: absolute;
	left: 50%;
	top: -8px;
	margin-left: -5px;
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 7.5px 8px 7.5px;
	border-color: transparent transparent lighten($color-blue, 33%) transparent;
}

#globalHeader .nav .nav-navbar .navbar-submenu > li > a {
	display: block;
	padding: 10px 0 10px 0;
	color: $color-steel;
}

#globalHeader .nav .nav-navbar .navbar-submenu > li > a:hover,
#globalHeader .nav .nav-navbar .navbar-submenu > li.current > a {
	color: $color-blue;
}

#globalHeader .nav .nav-navbar .navbar-submenu > li.current > a {
	font-weight: 800;
}



/******* GLOBAL CONTENT *******/

#globalContent *::selection,
#globalFooter *::selection {
	color: #FFFFFF;
	background: $color-blue;
}

#globalContent *::-moz-selection,
#globalFooter *::-moz-selection {
	color: #FFFFFF;
	background: $color-blue;
}

#globalContent {
	padding-top: $menuHeight;
}

body.systemMessage #globalContent {
	padding-top: $messageHeight + $menuHeight;
}



/******* GLOBAL TITLE *******/

#globalTitle {
	background: $color-blue;
}

#globalTitle > .wrapper {
	padding-top: 40px;
	padding-bottom: 20px;
	@extend .globalPadding;
}

#globalTitle > .wrapper:after {
	@include clearFix;
}

#globalTitle h1,
#globalTitle h2 {
	float: left;
	display: inline-block;
	font-family: $fontStack-secondary;
	color: #FFFFFF;
}

#globalTitle h1 {
	color: $color-yellow;
}

#globalTitle h2 {
	clear: both;
	margin-top: 10px;
	line-height: $fontSize-large;
}

#globalTitle h1 + .globalButton {
	float: right;
}



/******* GLOBAL TOP & BOTTOM *******/

.globalTop {
	padding-top: 80px;
}

.globalBottom {
	padding-bottom: 120px;
}



/******* GLOBAL VIDEO *******/

#globalVideo,
#globalVideo > .closeBackground {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

#globalVideo {
	pointer-events: none;
	opacity: 0;
	z-index: 100;
	position: fixed;
	background: transparentize(darken($color-blueDark, 14%), 0.20);
	@include transition (opacity 1s $easeOutExpo);
}

body.video #globalVideo {
	pointer-events: auto;
	opacity: 1;
	overflow: hidden;
}

#globalVideo > .closeBackground,
#globalVideo > .wrapper,
#globalVideo > .wrapper > .closeButton {
	position: absolute;
}

#globalVideo > .closeBackground {
	z-index: 0;
}

#globalVideo > .closeBackground:hover {
	cursor: pointer;
}

#globalVideo > .wrapper {
	z-index: 10;
	left: 50%;
	top: 50%;
}

#globalVideo > .wrapper > .container {
	opacity: 0;
	width: 100%;
	height: 100%;
	background: #000000;
	@include transform (scale(.2));
}

body.video #globalVideo > .wrapper > .container {
	opacity: 1;
	@include transitionTransform (1s $easeOutExpo);
	@include transform (scale(1));
}

#globalVideo > .wrapper > .container > iframe {
	width: 100%;
	height: 100%;
	border: none;
}

#globalVideo > .wrapper > .closeButton {
	opacity: .5;
	z-index: 10;
	left: 50%;
	bottom: -70px - 40px;
	display: block;
	margin-left: -(70px / 2);
	width: 70px;
	height: 70px;
	@include transition (opacity 0.5s $easeOutExpo);
}

#globalVideo > .wrapper > .closeButton:hover {
	opacity: 1;
}

#globalVideo > .wrapper > .closeButton > .icon {
	width: 100%;
	height: 100%;
}



/******* GLOBAL BUTTON *******/

.globalButton {
	display: table;
	padding: 0 20px 0 20px;
	width: auto;
	min-width: 180px;
	height: 35px;
	@extend %text-normal;
	font-weight: 700;
	text-align: center;
	color: $color-blue;
	border-radius: 35px;
	border: 2px solid $color-blue;
	text-decoration: none;
	@include transition (all .25s $easeOutExpo);
}

.globalButton.inline {
	display: inline-table;
}

.globalButton.small,
.globalButton.tiny {
	min-width: 0;
}

.globalButton.small {
	padding: 0 10px 0 10px !important;
	height: 30px;
	border-radius: 30px;
	@extend %text-small;
}

.globalButton.tiny {
	padding: 0 5px 0 5px !important;
	height: 20px;
	border-radius: 20px;
	@extend %text-extraSmall;
}

.globalButton.fill {
	border: none;
	color: #FFFFFF;
	background-color: $color-blue;
}

.globalButton.light {
	border-color: #FFFFFF;
	color: #FFFFFF;
}

.globalButton:hover {
	cursor: pointer;
	color: #FFFFFF !important;
	background-color: $color-blue;
}

.globalButton.fill:hover {
	background-color: $color-blueDark;
}

.globalButton.light:hover {
	color: $color-blue;
	background-color: #FFFFFF;
}

.globalButton.light:hover {
	color: $color-blue !important;
}

.globalButton > .wrapper,
.globalButton > .icon,
.globalButton .wistia_click_to_play {
	display: table-cell;
	vertical-align: middle;
}

.globalButton > .icon {
	width: 18px;
}

.globalButton > .icon + .wrapper,
.globalButton > .wrapper + .icon {
	padding-left: 10px;
}

.globalButton > .icon > .icon-element {
	display: block;
	width: 18px;
	height: 18px;
}

.globalButton:hover > .icon > .icon-element > .layer,
.globalButton.light > .icon > .icon-element > .layer,
.globalButton.fill > .icon > .icon-element > .layer {
	fill: #FFFFFF !important;
}

.globalButton.light:hover > .icon > .icon-element > .layer {
	fill: $color-blue !important;
}

.globalButton,
.globalButton:hover > .icon > .icon-element > .layer {
	@include transition (all 0.15s $easeOutExpo);
}

.globalButton.fill:hover > .icon > .icon-element > .layer {
	@include transition (none);
}

.globalButton.disabled {
	pointer-events: none;
	opacity: .5;
}

.globalButton.disabled:hover {
	cursor: not-allowed;
}



/******* GLOBAL COLUMNS *******/

.globalColumns:after {
	@include clearFix;
}

.globalColumns .globalColumns-left,
.globalColumns .globalColumns-right {
	@include calc (width, "50% - 20px");
}

.globalColumns .globalColumns-left {
	float: left;
}

.globalColumns .globalColumns-right {
	float: right;
}

.globalColumns .globalColumns-left > .globalForm-section:last-child,
.globalColumns .globalColumns-right > .globalForm-section:last-child {
	margin-bottom: 0;
}



/******* GLOBAL FORM *******/

.globalForm {
	position: relative;
}

.globalForm.loading:before,
.globalForm.loading:after {
	content: "";
	position: absolute;
	display: block;
}

.globalForm.loading:before {
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: transparentize(#FFFFFF, .5);
}

.globalForm.loading:after {
	z-index: 20;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	width: 80px;
	height: 80px;
	@include animation (1s circle infinite linear);
	border: 5px solid $color-blue;
	border-top-color: transparent;
	border-radius: 100%;
}

@-webkit-keyframes circle {
	0% { -webkit-transform: rotate(0); }
	100% {-webkit-transform: rotate(360deg); }
}

@-moz-keyframes circle {
	0% { -moz-transform: rotate(0); }
	100% { -moz-transform: rotate(360deg); }
}

@-ms-keyframes circle {
	0% { -ms-transform: rotate(0); }
	100% { -ms-transform: rotate(360deg); }
}

@-o-keyframes circle {
	0% { -o-transform: rotate(0); }
	100% { -o-transform: rotate(360deg); }
}

@keyframes circle {
	0% { transform: rotate(0); }
	100% { transform: rotate(360deg); }
}

.globalForm fieldset {
	border: none;
}

.globalForm .globalForm-section,
.globalForm .globalForm-errors,
.globalForm .globalForm-success {
	margin-bottom: 20px;
}

.globalForm .globalForm-section:last-child {
	margin-bottom: 0;
}

.globalForm.loading label,
.globalForm.loading input,
.globalForm.loading select,
.globalForm.loading textarea {
	pointer-events: none;
}

.globalForm label {
	margin-bottom: 10px;
}

.globalForm label .globalForm-required,
.globalForm label .note {
	@extend %text-small;
	color: $color-steelLight;
}

.globalForm label .globalForm-required {
	padding-left: 2px;
}

.globalForm label .note {
	display: block;
	margin-top: 2px;
}

.globalForm label > .note:first-child {
	margin-top: 5px;
}

.globalForm label,
.globalForm input,
.globalForm select,
.globalForm textarea {
	display: block;
	width: 100%;
	@extend %text-normal;
	color: $color-steel;
}

.globalForm input[type="text"],
.globalForm input[type="email"],
.globalForm input[type="tel"],
.globalForm .globalForm-file,
.globalForm select,
.globalForm textarea {
	apperance: none;
	border: none;
	font-family: $fontStack-primary;
	border: 1px solid $color-backgroundAltDark;
	border-radius: 4px;
	background-color: $color-backgroundAlt;
	-webkit-appearance: none;
	apperance: none;
	outline: none;
}

.globalForm input[type="text"],
.globalForm input[type="email"],
.globalForm input[type="tel"],
.globalForm select {
	padding: 0 10px 0 10px;
	height: 40px;
}

.globalForm .globalForm-file {
	padding: 10px;
}

.globalForm select {
	padding-right: (10px + 20px + 10px);
	background-image: url("../../img/global/globalForm_select.svg");
	background-repeat: no-repeat;
	@include calcBackgroundHorizontal ("100% - 10px");
}

.globalForm textarea {
	padding: 10px;
	resize: vertical;
}

.globalForm .g-recaptcha {
	margin-top: 40px;
	margin-bottom: 40px;
	display: table;
}

.globalForm .globalForm-submit {
	opacity: 0;
	display: none;
	visibility: hidden;
}

/* GLOBAL FORM: RESULTS */

.globalForm-result {
	padding: 20px 0 20px 0;
	color: #FFFFFF;
}

.globalForm-result.success {
	background: $color-green;
}

.globalForm-result.errors {
	background: $color-red;
}

.globalForm-result p {
	font-weight: 700;
}

.globalForm-result ul {
	padding-left: 20px;
}

.globalForm-result ul > li {
	margin-left: 10px;
}

.globalForm-result > .wrapper > * {
	margin-bottom: 10px;
}

.globalForm-result > .wrapper > *:last-child {
	margin-bottom: 0;
}



/******* GLOBAL FOOTNOTE *******/

.globalFootnote {
	color: $color-steelLight;
	@extend %text-extraSmall;
}



/******* GLOBAL FOOTNOTES *******/

.globalFootnotes {
	background: $color-backgroundDark;
}

.globalFootnotes > .wrapper {
	padding-top: 40px;
	@extend .globalPadding;
}

.globalFootnotes > .wrapper:after {
	content: "";
	display: block;
	margin-top: 40px;
	width: 100%;
	height: 1px;
	background: $color-background;
}

.globalFootnotes p,
.globalFootnotes li {
	margin-bottom: 0;
	@extend %text-extraSmall;
	color: $color-steelLight;
}

.globalFootnotes p {
	line-height: $fontSize-extraSmall;
}

.globalFootnotes li {
	line-height: $fontSize-extraSmall-lineHeight;
}

.globalFootnotes a {
	color: inherit;
}

.globalFootnotes p + p {
	margin-top: 10px;
}

.globalFootnotes ul,
.globalFootnotes ol {
	list-style-position: outside;
}

.globalFootnotes li {
	margin-left: 1em;
	margin-bottom: 10px;
}

.globalFootnotes li:last-child {
	margin-bottom: 0;
}

.globalFootnotes a:hover {
	color: $color-steel;
}

.globalFootnotes p + ul,
.globalFootnotes p + ol,
.globalFootnotes ul ul,
.globalFootnotes ol ol,
.globalFootnotes ul ol,
.globalFootnotes ol ul {
	margin-top: 10px;
}

.globalFootnotes ul + p,
.globalFootnotes ol + p {
	margin-top: 30px;
}

.globalFootnotes ul.blank,
.globalFootnotes ol.blank {
	list-style: none;
}

.globalFootnotes ul.roman,
.globalFootnotes ol.roman {
	list-style-type: lower-roman;
}

.globalFootnotes h3{
  margin-bottom: 15px;
}

/******* GLOBAL FOOTER *******/

#globalFooter {
	z-index: 10;
	position: relative;
	background: $color-backgroundDark;
}

#globalFooter > .wrapper a {
	color: $color-steel;
}

#globalFooter > .wrapper a:hover {
	color: #FFFFFF;
	text-decoration: underline;
}

#globalFooter > .wrapper .title {
	@extend %text-normal;
	font-weight: 700;
	color: #FFFFFF;
}

#globalFooter > .wrapper {
	padding-top: 60px;
	padding-bottom: 60px;
}

#globalFooter > .wrapper:after,
#globalFooter .globalFooter-terms > .wrapper:after {
	@include clearFix;
}

#globalFooter ul {
	list-style: none;
}

#globalFooter ul li a {
	text-decoration: none;
}

#globalFooter .left{
	width: 50%;
}

#globalFooter .left .apple-the-apple-log{
	font-family: Open Sans;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.1px;
	line-height: 19px;
	max-width: 470px;
	text-align: left;
	padding-top: 40px;
	float: left;
}

#globalFooter .right .apple-the-apple-log{
	display: none;
}

#globalFooter .left:after,
#globalFooter .social:after {
	@include clearFix;
}

#globalFooter .left,
#globalFooter .social li,
#globalFooter .sitemap {
	float: left;
}

$social-icon-names: "facebook", "instagram", "youtube", "twitter", "pinterest", "google";
$social-icon-size: 30px;
$social-icon-margin: 20px;

#globalFooter .social {
	margin-top: 5px;
	margin-bottom: 40px + 5px;
	width: ($social-icon-size * length($social-icon-names)) + ($social-icon-margin * (length($social-icon-names) - 1));
}

#globalFooter .social li {
	margin-right: $social-icon-margin;
}

#globalFooter .social li:last-child {
	margin-right: 0;
}

#globalFooter .social li a,
#globalFooter .social li a .icon-element {
	display: block;
}

#globalFooter .social li a .icon-element {
	width: $social-icon-size;
	height: $social-icon-size;
}

#globalFooter .social li a:hover .icon-element .layer {
	fill: #FFFFFF !important;
}

#globalFooter .sitemap {
	margin-right: 60px;
}

#globalFooter .sitemap.learnMore {
	margin-right: 0;
}

#globalFooter .sitemap li {
	margin-top: 15px;
	@extend %text-small;
}

#globalFooter .sitemap li:first-child {
	margin-top: 0;
}

#globalFooter .sitemap li.title {
	float: none;
	width: 100%;
}

#globalFooter .right {
	position: relative;
	float: right;
	width: 500px;
}

#globalFooter .download {
	float: left;
}

#globalFooter .download > a,
#globalFooter .download > a img {
	width: 135px;
	height: 40px;
}

#globalFooter .download > a {
	float: left;
	display: block;
	margin-right: 20px;
}

#globalFooter .download > a:last-child {
	margin-right: 0;
}

#globalFooter .language {
	position: relative;
	float: right;
	width: 150px;
}

#globalFooter .language .language-current,
#globalFooter .language .language-list {
	background: $color-steel;
}

#globalFooter .language .language-current:hover {
	color: #FFFFFF;
	text-decoration: none;
	background: $color-steelLight;
}

#globalFooter .language .language-current {
	display: table;
	width: 100%;
	height: 40px;
	border-radius: 40px;
	color: #FFFFFF;
	text-decoration: none;
	@extend %text-small;
}

#globalFooter .language.expanded .language-current {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: (40px / 2);
	border-bottom-right-radius: (40px / 2);
}

#globalFooter .language .language-current > span {
	@include tableCell;
}

#globalFooter .language .language-current > .globe {
	width: 30px;
}

#globalFooter .language .language-current > .arrow {
	width: 25px;
}

#globalFooter .language .language-current > .wrapper {
	padding-left: 10px;
	width: auto;
	text-align: left;
}

#globalFooter .language .language-current > .globe:before,
#globalFooter .language .language-current > .arrow:before {
	content: "";
	display: block;
}

#globalFooter .language .language-current > .globe:before {
	margin-left: auto;
	width: 20px;
	height: 20px;
	background: url("../../img/global/globalNav_icons.svg") -60px 0 no-repeat transparent;
}

#globalFooter .language .language-current > .arrow:before {
	margin-right: auto;
	width: 10px;
	height: 6px;
	background: url("../../img/global/globalNav_icons.svg") 0 0 no-repeat transparent;
}

#globalFooter .language .language-list {
	position: absolute;
	left: 0;
	bottom: 40px;
	display: none;
	width: 100%;
	list-style: none;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	overflow: hidden;
}

#globalFooter .language.expanded .language-list {
	display: block;
}

#globalFooter .language .language-list li a {
	display: block;
	padding: 10px 0 10px 0;
	text-align: center;
	color: #FFFFFF;
	@extend %text-small;
}

#globalFooter .language .language-list a:hover,
#globalFooter .language .language-list li.current a {
	background: darken($color-steelLight, 10%);
}

#globalFooter .subscribe {
	clear: both;
	margin-bottom: 115px;
	padding-top: 40px;
}

#globalFooter .subscribe p {
	@extend %text-small;
}

#globalFooter .subscribe .subscribe-fields {
	position: relative;
	width: 100%;
}

#globalFooter .subscribe .message {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	left: 50%;
	bottom: 55px;
	display: block;
	margin-left: -(290px / 2) - (140px / 2);
	padding: 10px 0 10px 0;
	width: 290px;
	@extend %text-small;
	text-align: center;
	color: #FFFFFF;
	border-radius: 5px;
	@include transform (translateY(30px));
}

#globalFooter .subscribe .subscribe-fields.empty .message,
#globalFooter .subscribe .subscribe-fields.invalid .message,
#globalFooter .subscribe .subscribe-fields.sent .message,
#globalFooter .subscribe .subscribe-fields.feedback .message {
	opacity: 1;
	@include transform (translateY(0));
	@include transition (all .25s $easeOutBack);
}

#globalFooter .subscribe .message > span {
	display: none;
}

#globalFooter .subscribe .subscribe-fields:after {
	@include clearFix;
}

#globalFooter .subscribe .message:after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -8px;
	margin-left: -5px;
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 7.5px 0 7.5px;
	border-color: transparent;
}

#globalFooter .subscribe .subscribe-fields.empty .message .message-empty,
#globalFooter .subscribe .subscribe-fields.invalid .message .message-invalid,
#globalFooter .subscribe .subscribe-fields.sent .message .message-sent,
#globalFooter .subscribe .subscribe-fields.feedback .message .message-feedback {
	display: block;
}

#globalFooter .subscribe .subscribe-fields.empty .message,
#globalFooter .subscribe .subscribe-fields.invalid .message,
#globalFooter .subscribe .subscribe-fields.feedback .message {
	background: $color-red;
}

#globalFooter .subscribe .subscribe-fields.empty .message:after,
#globalFooter .subscribe .subscribe-fields.invalid .message:after,
#globalFooter .subscribe .subscribe-fields.feedback .message:after {
	border-top-color: $color-red;
}

#globalFooter .subscribe .subscribe-fields.sent .message {
	background: $color-green;
}

#globalFooter .subscribe .subscribe-fields.sent .message:after {
	border-top-color: $color-green;
}

#globalFooter .subscribe p.title {
	margin-bottom: 20px;
}

#globalFooter .subscribe .subscribe-fields {
	margin-top: 30px;
}

#globalFooter .subscribe .subscribe-input,
#globalFooter .subscribe .subscribe-button {
	height: 40px;
	border: none;
	apperance: none;
	-webkit-appearance: none;
	@include transition (opacity .25s $easeOutExpo);
	@extend %text-small;
}

#globalFooter .subscribe .subscribe-input {
	float: left;
	padding: 0 20px 0 20px;
	@include calc (width, "100% - 140px");
	border-top-left-radius: 40px;
	border-bottom-left-radius: 40px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: none;
	apperance: none;
	background: #FFFFFF;
}

#globalFooter .subscribe .subscribe-button {
	float: right;
	width: 140px;
	color: #FFFFFF;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 40px;
	border-bottom-right-radius: 40px;
	background: $color-steel;
}

#globalFooter .subscribe .subscribe-button:hover {
	cursor: pointer;
	background: $color-steelLight;
}

#globalFooter .subscribe .subscribe-input[disabled],
#globalFooter .subscribe .subscribe-button[disabled] {
	opacity: .5;
	pointer-events: none;
}

#globalFooter .ewa-logo {
	display: block;
	float: left;
	width: 88px;
	height: 50px;
	background: url("../../img/global/globalFooter_ewa_logo.png") right center no-repeat transparent;
}

#globalFooter .license {
	float: right;
	@include calc (width, "100% - #{88px + 40px}");
	text-align: justify;
	@extend %text-extraSmall;
	line-height: $fontSize-extraSmall-lineHeight;
}

#globalFooter .globalFooter-terms {
	padding-top: 20px;
	padding-bottom: 20px;
	background: $color-background;
}

#globalFooter .globalFooter-terms .wrapper li {
	color: $color-steelLight;
	@extend %text-extraSmall;
}

#globalFooter .globalFooter-terms .wrapper li {
	margin-right: 20px;
	float: left;
}

#globalFooter .globalFooter-terms .wrapper li.importantNotice,
#globalFooter .globalFooter-terms .wrapper li.copyright {
	margin: 0;
}

#globalFooter .globalFooter-terms .wrapper li.copyright {
	float: right;
}

#globalFooter .globalFooter-terms .wrapper li a {
	display: block;
	color: #FFFFFF;
}

#globalFooter .globalFooter-terms .wrapper li a:hover {
	text-decoration: underline;
}



/******* RESPONSIVE *******/

@media (max-width: 1080px) {

	/******* GLOBAL FOOTER *******/

	#globalFooter .sitemap {
		margin-right: 40px;
		max-width: 300px;
	}

	#globalFooter .right {
		width: 450px;
	}

	#globalFooter .language {
		width: 130px;
	}

	#globalFooter .globalFooter-terms {
		text-align: center;
	}

	#globalFooter .globalFooter-terms .wrapper {
		font-size: 0;
	}

	#globalFooter .globalFooter-terms .wrapper li {
		float: none;
		display: inline-block;
		margin: 0;
		text-align: center;
	}

	#globalFooter .globalFooter-terms .wrapper li > a,
	#globalFooter .globalFooter-terms .wrapper li.copyright {
		font-size: 12px;
		line-height: 12px;
	}

	#globalFooter .globalFooter-terms .wrapper li > a {
		padding: 5px 10px 5px 10px;
	}

	#globalFooter .globalFooter-terms .wrapper li.copyright {
		margin: 10px 0 0 0 !important;
		width: 100%;
	}

}

@media (max-width: 1070px) and (min-width: 881px) {

	/******* GLOBAL HEADER *******/

	#globalHeader .nav .nav-navbar {
		padding-left: $menuLogoWidth + 12px;
	}

	#globalHeader .nav .nav-navbar > li > a {
		padding-left: 12px;
		padding-right: 12px;
	}

}

@media (max-width: 990px) {

	/******* GLOBAL FOOTER *******/

	#globalFooter .sitemap.gettingStarted {
		margin-right: 0;
	}

	#globalFooter .sitemap.learnMore {
		clear: both;
		margin-top: 40px;
		width: 100%;
	}

	#globalFooter .sitemap.learnMore li {
		float: left;
		width: 50%;
	}

	#globalFooter .sitemap.learnMore li.title {
		float: none;
		width: 100%;
	}

}

@media (max-width: 970px) and (min-width: 881px) {

	/******* GLOBAL HEADER *******/

	$menuLogoWidthSmall: 77px;
	$menuLogoHeightSmall: 35px;

	#globalHeader .nav .nav-logo {
		top: ($menuHeight / 2) - ($menuLogoHeightSmall / 2) - 5px;
		width: $menuLogoWidthSmall;
		height: $menuLogoHeightSmall;
	}

	#globalHeader .nav .nav-navbar {
		padding-left: $menuLogoWidthSmall + 10px;
	}

	#globalHeader .nav .nav-navbar > li > a {
		padding-left: 10px;
		padding-right: 10px;
	}

	/* GLOBAL HEADER: LARGE */

	$menuHomeHeightLarge: 130px;

	#globalHeader.large .nav .nav-logo {
		top: ($menuHomeHeightLarge / 2) - ($menuLogoHeightSmall / 2) - 5px !important;
	}

}

@media (min-width: 881px) {

	/******* GLOBAL HEADER *******/

	/* GLOBAL HEADER: LARGE */

	$menuHomeHeightLarge: 130px;

	#globalHeader.large .nav .nav-logo {
		top: ($menuHomeHeightLarge / 2) - ($menuLogoHeight / 2) - 5px;
	}

	#globalHeader.large .nav .nav-navbar > li {
		height: $menuHomeHeightLarge;
	}

	#globalHeader.large .nav .nav-navbar .navbar-submenu {
		top: $menuHomeHeightLarge - ($menuHomeHeightLarge / 4);
	}

}

@media (max-width: $maxWidth) {

	body,
	.globalList .globalList-item p {
		font-size: 12px;
		line-height: 16px;
	}

	body.menu {
		overflow: hidden;
	}



	/******* GLOBAL COMMON *******/

	h1 {
		font-size: 20px;
		line-height: 20px;
	}

	h2,
	.globalList .globalList-item h2 {
		font-size: 16px;
		line-height: 20px;
	}

	h3 {
		font-size: 14px;
		line-height: 18px;
	}

	p{
		margin-bottom: 5px;
	}



	/******* GLOBAL PADDING *******/

	.globalPadding {
		padding-left: 20px;
		padding-right: 20px;
	}



	/******* GLOBAL SYSTEM MESSAGE *******/

	#globalSystemMessage {
		position: absolute;
		left: 0;
		top: 0;
	}

	#globalSystemMessage.expanded:after {
		content: "";
		z-index: 0;
		position: fixed;
		left: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: transparentize($color-blueDark, .25);
	}

	#globalSystemMessage .icon {
		width: $messageIconSizeSmall;
	}

	.globalSystemMessage .icon:before {
		margin-left: -($messageIconSizeSmall / 2);
		margin-top: -($messageIconSizeSmall / 2);
		width: $messageIconSizeSmall;
		height: $messageIconSizeSmall;
	}

	.globalSystemMessage .icon:after {
		margin-left: -($messageIconSizeSmall * 3 / 2);
		margin-top: -($messageIconSizeSmall * 3 / 2);
		width: $messageIconSizeSmall * 3;
		height: $messageIconSizeSmall * 3;
	}

	#globalSystemMessage .text,
	#globalSystemMessage .expand {
		font-size: 10px !important;
	}

	#globalSystemMessage .text {
		padding-left: $messageTextPaddingSmall;
		padding-top: 15px;
		@include calc (width, "100% - #{$messageIconSizeSmall + $messageTextPaddingSmall}");
		line-height: 12px !important;
	}

	#globalSystemMessage.overflow .text {
		@include calc (width, "100% - #{$messageIconSizeSmall + $messageButtonSizeSmall + $messageTextPaddingSmall}");
	}

	#globalSystemMessage.expanded .text {
		@include calc (width, "100% - #{$messageIconSizeSmall}");
	}

	#globalSystemMessage .text:after,
	#globalSystemMessage .expand {
		width: $messageButtonSizeSmall;
	}

	#globalSystemMessage .expand {
		margin-left: $messageTextPaddingSmall;
		line-height: 10px;
	}

	#globalSystemMessage.expanded .expand {
		float: right;
		margin-top: 0;
		margin-bottom: 10px;
	}



	/******* GLOBAL HEADER *******/

	$menuMobileHeight: 50px;

	#globalHeader,
	body.menu #globalHeader {
		background: transparentize($color-blue, 0.075);
	}

	#globalHeader {
		height: $menuMobileHeight;
	}

	body.systemMessage #globalHeader {
		padding-top: $messageHeight;
		height: $messageHeight + $menuMobileHeight;
	}

	body.menu #globalHeader {
		height: 100%;
		height: 100vh;
	}

	#globalHeader .hamburger {
		display: block;
	}

	#globalHeader .nav .nav-navbar {
		pointer-events: none;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		opacity: 0;
		z-index: 0;
		position: fixed;
		left: 0;
		top: $menuMobileHeight;
		padding: 12px 0 50px 0;
		width: 100%;
		@include calc (height, "100% - #{$menuMobileHeight}");
		@include transform (translateZ(0));
		will-change: opacity, height;
	}

	body.systemMessage #globalHeader .nav .nav-navbar {
		top: $messageHeight + $menuMobileHeight;
		@include calc (height, "100% - #{$messageHeight + $menuMobileHeight}");
	}

	body.menu #globalHeader .nav .nav-navbar {
		pointer-events: auto;
		opacity: 1;
	}

	#globalHeader .nav .nav-logo {
		left: 20px;
		top: 10px;
		width: 65px;
		height: 30px;
	}

	#globalHeader .nav .nav-navbar > li.navbar-language > a,
	#globalHeader .nav .nav-navbar .navbar-submenu,
	#globalHeader .nav .nav-navbar .navbar-submenu:before,
	#globalHeader .nav .nav-navbar > li.navbar-dropdown > a > .wrapper:after {
		display: none;
	}

	#globalHeader .nav .nav-navbar > li,
	#globalHeader .nav .nav-navbar > li > a {
		opacity: 1;
	}

	#globalHeader .nav > .nav-navbar > li {
		display: block !important;
	}

	#globalHeader .nav .nav-navbar > li {
		float: none !important;
		opacity: 0;
		margin-bottom: 0;
		padding: 0;
		height: auto;
		@include transform (translateY(-10px) translateZ(0));
		will-change: opacity, transform;
	}

	#globalHeader .nav .nav-navbar > li:first-child {
		margin-top: 0;
	}

	#globalHeader .nav .nav-navbar > li > a {
		padding: 12px 20px 12px 20px;
		text-align: left;
		font-size: 20px;
		line-height: 20px;
		border: none;
	}

	#globalHeader .nav .nav-navbar > li.current > a {
		background: transparentize(#FFFFFF, .75);
	}

	body.menu #globalHeader .nav .nav-navbar > li {
		opacity: 1;
		@include transform (translateY(0px) translateZ(0));
		@include transition (all 0.75s $easeOutExpo);
	}

	$menu-list:	"home", "appFeatures", "globalTransfer", "whereToUse", "tngMastercard", "news", "about", "support";

	@each $current-menu-name in $menu-list {

		$i: index($menu-list, $current-menu-name);

		body.menu #globalHeader .nav .nav-navbar > li.navbar-#{$current-menu-name} {
			@include transitionDelay (#{0.08s * $i});
		}

	}

	#globalHeader .nav .nav-navbar > li.navbar-language,
	#globalHeader .nav .nav-navbar > li.navbar-language > .navbar-submenu > li {
		height: 50px;
	}

	#globalHeader .nav .nav-navbar > li.navbar-language {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		margin-top: 0;
		background: $color-blue;
		@include transform (translateY(40px) translateZ(0));
		will-change: transform;
	}

	body.menu #globalHeader .nav .nav-navbar > li.navbar-language {
		@include transform (translateY(0px) translateZ(0));
	}

	#globalHeader .nav .nav-navbar > li.navbar-language > .navbar-submenu {
		opacity: 1;
		position: static;
		display: block !important;
		margin: 0;
		width: 100%;
		font-size: 0;
		text-align: center;
		background: none;
		border-radius: 0;
		@include transform (none);
		@include transition (none);
	}

	#globalHeader .nav .nav-navbar > li.navbar-language > .navbar-submenu > li {
		display: inline-table;
	}

	#globalHeader .nav .nav-navbar > li.navbar-language > .navbar-submenu > li > a {
		padding: 0 15px 0 15px;
		color: #FFFFFF;
		@include tableCell;
	}

	#globalHeader .nav .nav-navbar > li.navbar-language > .navbar-submenu > li.current > a {
		background: lighten($color-blue, 10%);
	}


	/******* GLOBAL CONTENT *******/

	#globalContent {
		padding-top: 0 !important;
	}



	/******* GLOBAL TITLE *******/

	#globalTitle {
		min-height: 100%;
	}

	#globalTitle > .wrapper {
		padding-top: $messageHeight + 20px;
		padding-bottom: 20px;
	}

	body.systemMessage #globalTitle > .wrapper {
		padding-top: ($messageHeight + $menuMobileHeight) + 20px;
	}



	/******* GLOBAL TOP & BOTTOM *******/

	.globalTop {
		padding-top: ($messageHeight + $menuMobileHeight) + 40px;
	}

	#globalTitle + .globalTop {
		padding-top: 40px;
	}

	.globalBottom {
		padding-bottom: 60px;
	}



	/******* GLOBAL VIDEO *******/

	#globalVideo > .wrapper > .closeButton {
		opacity: 1;
		bottom: -40px - 20px;
		margin-left: -(40px / 2);
		width: 40px;
		height: 40px;
		@include transition (opacity 0.5s $easeOutExpo);
	}



	/******* GLOBAL BUTTON *******/

	.globalButton {
		padding: 0 20px 0 20px;
		min-width: 120px;
		height: 30px;
		border-radius: 30px;
		font-size: 12px !important;
		line-height: 12px !important;
	}

	.globalButton > .icon {
		padding-left: 5px;
		width: 12px;
	}

	.globalButton > .icon > .icon-element {
		width: 12px;
		height: 12px;
	}



	/******* GLOBAL COUNTER *******/

	.globalCounter li {
		width: 20px !important;
		height: 25px !important;
		font-size: 12px !important;
		line-height: 12px !important;
	}

	.globalCounter li.extra {
		width: auto !important;
	}



	/******* GLOBAL LIST *******/

	.globalList .globalList-item,
	.globalList.wide .globalList-item:nth-child(3) {
		margin-top: 20px;
		margin-right: 20px !important;
	}

	.globalList .globalList-item {
		margin-top: 20px !important;
		margin-right: 20px !important;
		@include calcImportant (width, "50% - 10px");
	}

	.globalList .globalList-item:nth-child(even) {
		margin-right: 0 !important;
	}

	.globalList.wide .globalList-item:nth-child(1),
	.globalList.wide .globalList-item:nth-child(2) {
		margin-top: 0 !important;
	}

	.globalList.eventsList .globalList-wrapper > .globalList-item {
		margin-bottom: 20px !important;
	}

	.globalList.eventsList .globalList-wrapper > .globalList-item:last-child {
		margin-bottom: 0;
	}

	.globalList .globalList-item .button {
		margin-top: 10px;
	}

	.globalList.sidebar .globalList-sidebar,
	.globalList.sidebar .globalList-wrapper {
		float: none;
		width: 100%;
	}

	.globalList.sidebar .globalList-sidebar {
		margin-bottom: 40px;
	}

	.globalList.merchantList .globalList-item .image img {
		width: auto;
		max-width: 130px;
	}



	/******* GLOBAL COLUMNS *******/

	.globalColumns .globalColumns-left,
	.globalColumns .globalColumns-right {
		float: none;
		width: 100%;
	}

	.globalColumns .globalColumns-left {
		margin-bottom: 20px;
	}



	/******* GLOBAL FORM *******/

	.globalForm .globalForm-section,
	.globalForm .globalForm-errors,
	.globalForm .globalForm-success {
		margin-bottom: 20px !important;
	}

	.globalForm label,
	.globalForm input,
	.globalForm select,
	.globalForm textarea,
	.globalForm .globalForm-success,
	.globalForm .globalForm-errors li {
		font-size: 12px !important;
	}

	.globalForm label,
	.globalForm .globalForm-success,
	.globalForm .globalForm-errors li {
		margin-bottom: 5px;
		line-height: 12px;
	}

	.globalForm label .globalForm-required {
		padding-left: 1px;
		font-size: 10px;
		line-height: 10px;
	}

	.globalForm label .note {
		font-size: 10px;
		line-height: 10px;
	}

	.globalForm label > .note {
		margin-top: 2px !important;
	}

	.globalForm input[type="text"],
	.globalForm input[type="email"],
	.globalForm input[type="tel"],
	.globalForm select {
		height: 35px !important;
	}

	.globalForm textarea {
		height: 100px;
	}

	.globalForm .g-recaptcha {
		margin-left: auto;
		margin-right: auto;
	}

	.globalForm .button {
		margin: 0 auto 0 auto;
	}

	/* GLOBAL FORM: RESULTS */

	.globalForm-result p,
	.globalForm-result li {
		font-size: $fontSize-extraSmall;
		line-height: $fontSize-extraSmall;
	}

	.globalForm-result ul {
		padding-left: 15px;
	}

	.globalForm-result ul > li {
		margin-left: 10px;
	}

	.globalForm-result > .wrapper > * {
		margin-bottom: 5px;
	}



	/******* GLOBAL FOOTNOTE *******/

	.globalFootnote {
		font-size: 8px;
		line-height: 8px;
	}



	/******* GLOBAL FOOTNOTES *******/

	.globalFootnotes > .wrapper {
		padding-top: 20px;
	}

	.globalFootnotes > .wrapper:after {
		margin-top: 20px;
	}

	.globalFootnotes p,
	.globalFootnotes a,
	.globalFootnotes li {
		font-size: 8px;
		line-height: 12px;
	}

	.globalFootnotes li {
		margin-bottom: 5px;
	}

	.globalFootnotes p + ol {
		margin-top: 0;
	}

	.globalFootnotes ol + p {
		margin-top: 10px;
	}



	/******* GLOBAL FOOTER *******/

	#globalFooter .left,
	#globalFooter .right {
		float: none;
		width: 100%;
	}

	#globalFooter > .wrapper {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	#globalFooter .left {
		margin-bottom: 40px;
	}

	#globalFooter .social {
		margin: 0 auto 40px auto;
	}

	#globalFooter .sitemap {
		@include calcImportant (width, "(100% / 3) - 10px");
		max-width: none;
	}

	#globalFooter .sitemap.tngWallet,
	#globalFooter .sitemap.gettingStarted {
		margin-right: 10px;
	}

	#globalFooter .sitemap.learnMore {
		margin: 0;
		clear: none;
	}
	#globalFooter .left .apple-the-apple-log{
		display: none;
	}
	#globalFooter .right .apple-the-apple-log{
		display: block;
		float: left;
		margin: 20px 0;
	}
	#globalFooter .subscribe{
		margin-bottom: 30px;
	}
}

@media (max-width: 580px) {

	/******* GLOBAL FOOTER *******/

	#globalFooter .left a,
	#globalFooter .globalFooter-terms a {
		display: block;
	}

	#globalFooter > .wrapper {
		padding-top: 40px + 20px + 40px;
		padding-bottom: 20px;
	}

	#globalFooter .social {
		margin-bottom: 40px;
	}

	#globalFooter .social li.title {
		float: none;
		width: 100%;
	}

	#globalFooter .left {
		margin-bottom: 40px;
	}

	#globalFooter .sitemap.tngWallet,
	#globalFooter .sitemap.gettingStarted {
		margin: 0 !important;
		width: 50% !important;
	}

	#globalFooter .sitemap.gettingStarted {
		float: right;
		text-align: right;
	}

	#globalFooter .sitemap.learnMore {
		clear: both;
		margin-top: 20px;
		width: 100% !important;
		text-align: center;
	}

	#globalFooter .sitemap li {
		margin-top: 0;
	}

	#globalFooter .sitemap li.title {
		margin-bottom: 5px;
	}

	#globalFooter .sitemap a {
		padding: 5px 0 5px 0;
	}

	#globalFooter .right,
	#globalFooter .download {
		position: static;
	}

	#globalFooter .right {
		padding-top: 0;
	}

	#globalFooter .download {
		float: none;
		margin: 0 auto 0 auto;
		width: 290px;
	}

	#globalFooter .language {
		position: absolute;
		top: 20px;
		left: 50%;
		margin-left: -(140px / 2);
	}

	#globalFooter .subscribe .title,
	#globalFooter .subscribe p {
		text-align: center;
	}

	#globalFooter .subscribe .subscribe-input {
		@include calc (width, "100% - 90px");
	}

	#globalFooter .subscribe .subscribe-button {
		width: 90px;
	}

	#globalFooter .subscribe .message {
		margin-left: -(290px / 2);
	}

	#globalFooter .license {
		font-size: 10px;
		line-height: 12px;
		@include calc (width, "100% - #{88px + 20px}");
	}

	#globalFooter .globalFooter-terms .wrapper li {
		margin: 0 !important;
	}

	#globalFooter .globalFooter-terms .wrapper li > a,
	#globalFooter .globalFooter-terms .wrapper li.copyright {
		font-size: 10px;
		line-height: 10px;
	}

	#globalFooter .globalFooter-terms .wrapper li > a {
		padding: 5px;
	}

}

@media (max-width: 330px) {

	/******* GLOBAL FOOTER *******/

	#globalFooter .download {
		width: 135px;
	}

	#globalFooter .download .download-apple {
		margin-bottom: 20px;
	}

}

.color-yellow{
	background-color: $color-yellow;
}